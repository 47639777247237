<template>
  <select
    v-model="value"
    :placeholder="props.placeholder"
    class="hoap-input hoap-select text-white placeholder:text-white">
    <option value="" disabled selected>{{ props.placeholder }}</option>
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
  import { PropType, computed } from 'vue'

  const props = defineProps({
    placeholder: String,
    options: {
      type: Array as PropType<Array<{ value: string; label: string }>>,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
  })

  const emit = defineEmits(['update:modelValue'])

  const value = computed({
    get: () => props.modelValue,
    set: (v) => emit('update:modelValue', v),
  })
</script>
