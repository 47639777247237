<template>
  <input v-model="value" :placeholder="props.placeholder" class="hoap-input text-white placeholder:text-white" />
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    placeholder: {
      type: String,
    },
    modelValue: {
      type: String,
      default: '',
    },
  })

  const emit = defineEmits(['update:modelValue'])

  const value = computed({
    get: () => props.modelValue,
    set: (v) => emit('update:modelValue', v),
  })
</script>
